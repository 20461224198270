const logoPrimary = require("/src/resources/images/logo_abstracta.svg");
const logoWhite = require("/src/resources/images/logo_abstracta_white.svg");

var currentLogoNavMobile = null;

function menuScrollHandler() {
    let desktopNav = $("#navbar");
    let mobileNav = $("#navbar-mobile");
    const isScrolled = desktopNav.hasClass("nav-scroll");

    if ((document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)) {
        if (!isScrolled) {
            desktopNav.addClass("nav-scroll")
            mobileNav.addClass("nav-scroll")

            if (desktopNav.hasClass("white")) {
                desktopNav.find(".logo-menu").attr("src", logoPrimary)
            }
            if (mobileNav.hasClass("white")) {
                mobileNav.find(".logo-menu").attr("src", logoPrimary)
            }
        }
    } else {
        if (isScrolled) {
            $("#navbar").removeClass("nav-scroll")
            $("#navbar-mobile").removeClass("nav-scroll")
            if (desktopNav.hasClass("white")) {
                desktopNav.find(".logo-menu").attr("src", logoWhite)
            }
            if (mobileNav.hasClass("white")) {
                mobileNav.find(".logo-menu").attr("src", logoWhite)
            }
        }
    }
}

function initMobileSubMenu() {
    const toggleDropdown = function (e) {
        if (e.target.tagName.toLowerCase() == "a") return
        const dropDownElem = $(this).parent();
        const isActive = $(dropDownElem).hasClass("open");

        $(dropDownElem).parent().find(".submenu").slideUp();
        $(dropDownElem).siblings().removeClass("open");

        if (isActive) {
            $(dropDownElem).removeClass("open");
            $(dropDownElem).find(".submenu").slideUp("fast");
            $(dropDownElem).find(".dropdown-toggle").attr("aria-expanded", "false");
        } else {
            $(dropDownElem).addClass("open");
            $(dropDownElem).find(".submenu").slideDown("fast");
            $(dropDownElem).find(".dropdown-toggle").attr("aria-expanded", "true");
        }
    }
    $("#mySidenav .dropdown-mobile .dropdown-title").click(toggleDropdown);
}

window.openMobileNav = function () {
    currentLogoNavMobile = $("#navbar-mobile .logo-menu").attr("src");
    $("#navbar-mobile .logo-menu").attr("src", logoPrimary);
    const navToggle = $("#btn-toggle-mobile-nav");
    navToggle.attr("aria-label", "Cerrar menu de navegación");
    navToggle.attr("aria-expanded", "true");
    navToggle.find("span").html("close");
    const sideNav = $("#mySidenav");
    sideNav.addClass("opened").attr("aria-modal", "true").slideDown("fast", () => {
        $("html").addClass("sidenav-opened");
        sideNav.find(".sidenav-footer").fadeIn("fast");
    })
    setTimeout(() => {
        sideNav.find(".dropdown-mobile").each(function () {
            $(this).delay("150", "mobileDrop").slideDown("fast");
        })
    }, 100)
}

window.closeMobileNav = function () {
    if (currentLogoNavMobile) $("#navbar-mobile .logo-menu").attr("src", currentLogoNavMobile);
    const navToggle = $("#btn-toggle-mobile-nav");
    navToggle.attr("aria-label", "Abrir menu de navegación");
    navToggle.attr("aria-expanded", "false");
    navToggle.find("span").html("menu");
    $("html").removeClass("sidenav-opened");
    const sideNav = $("#mySidenav");
    sideNav.removeClass("opened").attr("aria-modal", "false").slideUp("fast");
    sideNav.find(".sidenav-footer").fadeOut("fast");
    sideNav.find(".dropdown-mobile").each(function () {
        $(this).delay("100", "mobileDrop").slideUp("fast");
    })
}

function toggleMobileNav() {
    if ($("#mySidenav").hasClass("opened")) closeMobileNav();
    else openMobileNav();
}

function initMobileNav() {
    initMobileSubMenu();
    document.querySelector("#btn-toggle-mobile-nav").addEventListener("click", toggleMobileNav);
}

function initDesktopNav() {
    const navSel = "#navbar";

    $(`${navSel} .dropbtn`).on('shown.bs.dropdown', function () {
        $(navSel).addClass("open");
    })
    $(`${navSel} .dropbtn`).on('hide.bs.dropdown', function () {
        $(navSel).removeClass("open");
    })
}

function qualifyURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return a.cloneNode(false).href;
}

function highlightCurrentNavItem() {
    const currentUrl = new URL(window.location.href);

    // Remover clases activas existentes
    $("#navbar .btn-group").removeClass("active");
    $("#navbar a").removeClass("active");
    $("#mySidenav .nav-item").removeClass("active");
    $("#mySidenav li").removeClass("active");

    // Función para verificar la coincidencia de la URL
    const isUrlMatch = (elementUrl, currentUrl) => {
        // Comparar la URL actual con la URL del elemento, considerando el pathname
        return currentUrl.pathname === new URL(elementUrl).pathname;
    };

    // Resaltar elemento activo en navbar
    $("#navbar a").each(function () {
        const elementUrl = qualifyURL($(this).attr("href"));
        if (isUrlMatch(elementUrl, currentUrl)) {
            $(this).closest(".btn-group").addClass("active");
            $(this).addClass("active");
        }
    });

    // Resaltar elemento activo en el menú lateral (sidenav)
    $("#mySidenav a").each(function () {
        const elementUrl = qualifyURL($(this).attr("href"));
        if (isUrlMatch(elementUrl, currentUrl)) {
            $(this).closest(".nav-item").addClass("active");
            $(this).closest("li").addClass("active");
        }
    });
}


window.addEventListener('scroll', menuScrollHandler, { passive: true });

window.addEventListener("load", function () {
    menuScrollHandler();
    highlightCurrentNavItem()
    initMobileNav();
    initDesktopNav();
    window.addEventListener("hashchange", highlightCurrentNavItem)
});